import { ENVIRONMENT } from '../app/_utils/env.util';
import { LOG_LEVEL } from '../app/_utils/log-level.util';

export const environment = {
    apiUrl: 'https://api.staging-littlephil.org',
    authUrl: 'https://auth.staging-littlephil.org',
    businessName: 'Little Phil',
    cookieDomain: '.staging-littlephil.org',
    env: ENVIRONMENT.staging,
    establishmentYear: 2018,
    googleMapsAPIKey: 'AIzaSyB3DGI4IH6BzDjkCKBW4Dv9cpzesDTrYS4',
    googleTagManagerId: 'GTM-M4RFH4V',
    intercomAppId: 'uh8yre4m',
    logLevel: LOG_LEVEL.info,
    shareLinkUrl: 'https://share.staging-littlephil.org',
    production: true,
    recaptchaV3SiteKey: '6LdTXOcUAAAAAIxBP8rkGUclgnVwy5xCl9WcOUQl',
    webBaseUrl: 'https://staging-littlephil.org',
    widgetUrl: 'https://widgets.staging-littlephil.org',
};
